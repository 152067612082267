var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row search-input-container"},[_c('v-combobox',{ref:"autocomplete",staticClass:"search-input pr-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'small rounded-lg' : 'big',attrs:{"clearable":"","filled":"","solo":"","dense":"","height":_vm.$vuetify.breakpoint.smAndDown ? 43 : 48,"items":_vm.getItems,"search-input":_vm.search,"no-filter":"","label":_vm.isListening
        ? _vm.$t('navbar.search.speechActive')
        : _vm.$t('navbar.search.searchPlaceholder'),"placeholder":_vm.isListening
        ? _vm.$t('navbar.search.speechActive')
        : _vm.$t('navbar.search.searchPlaceholder'),"flat":"","item-text":"name","item-value":"name","hide-details":"","hide-no-data":"","return-object":"","type":"search","menu-props":{
      closeOnContentClick: true,
      contentClass: 'searches-menu'
    }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"focus":_vm.onFocus,"blur":_vm.onBlur},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();$event.stopPropagation();return _vm.doSearch.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex w-100 primary--text",class:item.type == 'FastSearch' ? 'fast-search-btn' : ''},[(item.type == 'Categorie')?_c('v-list-item-icon',{staticClass:"search-category-img"},[_c('img',{staticClass:"category-image",attrs:{"src":_vm.getImage(item),"onerror":"this.src='/no-icon.png'"}})]):(item.type == 'Ricerche')?_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteWord(item)}}},[_vm._v(" $close ")])],1):(item.type == 'FastSearch')?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$fastSearch")])],1):_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$search")])],1),_c('v-list-item-content',{on:{"click":function($event){item.type == 'FastSearch'
              ? _vm.openFastSearchDialog()
              : _vm.doSearch(item)}}},[_c('v-list-item-title',[(item.type == 'FastSearch')?_c('span',{staticClass:"font-weight-bold primary--text text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("fastSearch.searchInput.label"))+" ")]):_c('text-highlight',{attrs:{"queries":_vm.search ? _vm.search : '*'}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(item.type == 'Categorie')?_c('v-list-item-subtitle',[_vm._v(" Cerca "),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v(" in "+_vm._s(item.name)+" ")]):_vm._e(),(item.type == 'Marche')?_c('v-list-item-subtitle',[_vm._v(" Cerca "),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v(" con marca "+_vm._s(item.name)+" ")]):_vm._e()],1),_c('v-list-item-action',{staticClass:"my-0"},[(item.count)?_c('div',{staticClass:"badge"},[_vm._v(" "+_vm._s(item.count)+" ")]):_vm._e(),(item.type == 'Ricerche')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.search = item.name}}},[_vm._v(" $arrowTopLeft ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"prepend-inner"},[(_vm.isCordova)?_c('Barcode',{on:{"input":_vm.doSearch}}):_c('v-icon',{attrs:{"aria-label":"Cerca","color":"black"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.doSearch.apply(null, arguments)}}},[_vm._v("$search")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('template',{slot:"append"},[_c('v-btn',{attrs:{"depressed":"","height":"48px","color":"grey lighten-2"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.doSearch.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("navbar.search.searchBtn")))])],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }